<template>
    <div class="row mx-0 bg-white br-8 p-3">
        <p class="col-12 px-0 text-general mb-3 f-600 f-18">
            Misiones
        </p>
        <div class="col-12 px-0 scroll-none overflow-auto pb-4">
            <div class="mt-2 a-center pl-2" style="display:inline-flex;">
                <div v-for="(ms, m) in misiones" :key="m" class="card-misiones mr-3" @click="$router.push({name: 'vendedor.recompensas.misiones-mes'})">
                    <p class="f-12 text-center text-gris2 my-2">
                        {{ ms.tipo == 1 ? ms.mes : 'Evento' }}
                    </p>
                    <div class="row mx-0 mt-2 justify-center">
                        <div class="position-relative">
                            <el-progress type="circle" :percentage="ms.porcentaje" :color="ms.tipo == 1 ? '#62E1A4' : '#7140D9'" :width="100" />
                            <div class="inside position-absolute">
                                <img :src="`/img/gaming/${ms.img}`" height="80  " width="80" />
                                <div v-if="ms.porcentaje == 100" class="checked">
                                    <i class="icon-ok-circled-outline f-17 " :class="ms.tipo == 1 ? 'text-green' : 'text-purple'" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center text-gris2 f-12 f-500 mt-1">
                        {{ms.movimiento}}
                    </p>
                    <p class="text-center text-gris2 f-12 f-600 mt-1">
                        {{ ms.cant }}
                    </p>
                    <div class="row mx-0 justify-center my-2">
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/gaming/i_moneda.svg" height="20" />
                            <span class="mx-2">{{ms.cantCoin}}</span>
                        </div>
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/gaming/i_gema.svg" height="20" />
                            <span class="mx-2">{{ms.cantGem}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            misiones: [
                {
                    tipo: 1,
                    mes: 'Septiembre',
                    img: 'i_dinero.png',
                    movimiento: 'Ventas',
                    porcentaje: 85,
                    cant: '$ 51.000 / $ 100.000',
                    cantCoin: 15,
                    cantGem: 25
                },
                {
                    tipo: 1,
                    mes: 'Agosto',
                    img: 'i_checklist.png',
                    movimiento: 'Compras',
                    porcentaje: 50,
                    cant: '$ 51.000 / $ 100.000',
                    cantCoin: 15,
                    cantGem: 25
                },
                {
                    tipo: 1,
                    mes: 'Noviembre',
                    img: 'i_bag_money.png',
                    movimiento: 'Ventas',
                    porcentaje: 100,
                    cant: '$ 51.000 / $ 100.000',
                    cantCoin: 15,
                    cantGem: 25
                },
                {
                    tipo: 2,
                    img: 'i_bag_money.png',
                    movimiento: 'Compras',
                    porcentaje: 100,
                    cant: '$ 51.000 / $ 100.000',
                    cantCoin: 15,
                    cantGem: 25
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.card-misiones{
    width: 148px;
    min-height: 199px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    .circled-wrap{
        width: 80px;
        height: 80px;
        background: #FFFFFF;
        padding: 5px;
        position: relative;
        border-radius: 50%;
        border: 5px solid #DFE4E8;
        
        
    }
}

.inside{
    height: 80px;
    width: 80px;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #E9ECEF;
    border: 8px solid #DFE4E8;
    .checked{
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -15px;
            right: -11px;
            background-color: #FFFFFF;
            box-shadow: 0px 3px 6px #00000014;
        }
}
.text-purple{
    color: #7140D9;
}
</style>